import localeRepository from '@/api/locale';
import languageStorage from './languageStorage';

async function selectLanguageFromPreferred(preferred, locales) {
  let preferredLanguage;

  preferred.some((language) => {
    const code = language.substr(0, 2);

    const locale = locales.all().find((lang) => lang.code === code);

    if (!locale) {
      return false;
    }

    if (locale.supported) {
      preferredLanguage = locale.code;
    } else {
      preferredLanguage = locale.alias;
    }

    return true;
  });

  return Promise.resolve(preferredLanguage);
}

async function fromLocalStorage() {
  return Promise.resolve(languageStorage.getLocale());
}

async function fromNavigatorLanguages(locales) {
  if (!locales || !navigator.languages) {
    return Promise.resolve(undefined);
  }

  return selectLanguageFromPreferred(navigator.languages, locales);
}

async function fromNavigatorLanguage(locales) {
  if (!locales || !navigator.language) {
    return Promise.resolve(undefined);
  }

  const locale = locales
    .all()
    .find((lang) => lang.code === navigator.language.substr(0, 2));

  return Promise.resolve(locale ? locale.code : undefined);
}

async function fromAcceptLanguageHeader(locales) {
  let header = '';

  try {
    header = await localeRepository.get();
  } catch (error) {
    return Promise.resolve(undefined);
  }

  if (typeof header !== 'string') {
    return Promise.resolve(undefined);
  }

  const preferred = header.split(',').map((locale) => locale.split(';')[0]);

  return selectLanguageFromPreferred(preferred, locales);
}

export default [
  fromLocalStorage,
  fromNavigatorLanguages,
  fromAcceptLanguageHeader,
  fromNavigatorLanguage,
];
