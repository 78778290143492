import tokenStorage from '@/services/tokenStorage';

// Do not allow authenticated users to visit page
export default async function ({ from, next }) {
  const loggedIn = tokenStorage.hasAccessToken();

  if (!loggedIn) {
    return next();
  }

  return next({
    name: 'home',
    params: { lang: from.params.lang },
  });
}
