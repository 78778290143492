/* eslint-disable no-underscore-dangle */
import { i18n } from '@/i18n';
import { extend, configure, setInteractionMode } from 'vee-validate';
import {
  between,
  confirmed,
  email,
  image,
  mimes,
  max,
  min,
  numeric,
  required,
  size,
} from 'vee-validate/dist/rules';

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

extend('between', between);
extend('confirmed', confirmed);
extend('email', email);
extend('image', image);
extend('mimes', mimes);
extend('max', max);
extend('min', min);
extend('numeric', numeric);
extend('required', required);
extend('size', size);

setInteractionMode('eager');
