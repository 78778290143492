import Vue from 'vue';
import VTooltip from 'v-tooltip';
import * as VueGoogleMaps from 'vue2-google-maps';
import UUID from 'vue-uuid';

import { i18n } from '@/i18n';
import Localizer from '@/plugins/Localizer';
import VuePageTransition from 'vue-page-transition';
import languageStorage from './i18n/languageStorage';
import App from './App';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.use(VuePageTransition);
require('@/validation/vee-validate');
require('@/http');

Vue.use(Localizer);
Vue.use(VTooltip);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAArBMTdA4uj3MMSz69J1bUYRCRxBQd2gQ',
    libraries: 'places',
    language: languageStorage.getLocale(),
  },
});
Vue.use(UUID);

// Register layouts
Vue.component('Default', () => import(`@/layouts/default/Default`));

Vue.config.productionTip = false;

const FontFaceObserver = require('fontfaceobserver');

const font = new FontFaceObserver('Open Sans');

font.load().then(() => {
  store.commit('setFontsLoaded', true);
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
