import Vue from 'vue';
import VueRouter from 'vue-router';
import beforeEach from '@/router/hooks/beforeEach';
import store from '@/store';
import authRoutes from './auth';
import reviews from './reviews';
import books from './books';
import profile from './user';

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...reviews,
  ...books,
  ...profile,
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'Default',
      theme: 'Light',
    },
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home'),
  },

  {
    path: '/about',
    name: 'about',
    meta: {
      layout: 'Default',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/about/About'),
  },

  {
    path: '/contact',
    name: 'contact',
    meta: {
      layout: 'Default',
    },
    component: () =>
      import(/* webpackChunkName: "contact" */ '@/views/contact/Contact'),
  },

  {
    path: '/terms-and-conditions',
    name: 'terms',
    meta: {
      layout: 'Default',
    },
    component: () =>
      import(
        /* webpackChunkName: "terms" */ '@/views/terms/TermsAndConditions'
      ),
  },

  {
    path: '/privacy-policy',
    name: 'policy',
    meta: {
      layout: 'Default',
    },
    component: () =>
      import(/* webpackChunkName: "policy" */ '@/views/privacy/PrivacyPolicy'),
  },

  {
    path: '404',
    name: 'not-found',
    meta: {
      layout: 'Default',
    },

    component: () =>
      import(/* webpackChunkName: "not-found" */ '@/views/404/NotFound'),
  },

  {
    path: '*',
    redirect: '/404',
  },
];

const locales = store.state.locales.supported
  .map((locale) => locale.code)
  .join('|');

const localizedRoutes = routes.map((route) => ({
  ...route,
  path: `/:lang(${locales})?${route.path}`,
}));

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...localizedRoutes],
});

router.beforeEach(beforeEach);

export default router;
