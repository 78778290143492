import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/translations/en.json';
import validationMessages from '@/translations/validation/en.json';
import api from '@/http/client';
import languageStorage from './languageStorage';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en: { ...messages, validation: validationMessages } },
  silentFallbackWarn: true,
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  enableInSFC: true,
});

// preloaded language
const loadedLanguages = ['en'];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  api.setHeader('Accept-Language', lang);
  languageStorage.saveLocale(lang);
  document.querySelector('html').setAttribute('lang', lang);
  document.title = i18n.t('AppTitle');
  return lang;
}

export async function loadTranslationsAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  const [globals, validation] = await Promise.all([
    import(
      /* webpackChunkName: "i18n/[request]" */
      `@/translations/${lang}.json`
    ),
    import(
      /* webpackChunkName: "i18n/validation/[request]" */
      `@/translations/validation/${lang}.json`
    ),
  ]);

  i18n.setLocaleMessage(lang, {
    ...globals.default,
    validation: validation.default,
  });

  loadedLanguages.push(lang);

  return Promise.resolve(setI18nLanguage(lang));
}
