import auth from '@/middlewares/auth';

export default [
  {
    path: '/books/:isbn/reviews/create',
    name: 'reviews.create',
    meta: {
      layout: 'Default',
      middleware: auth,
    },
    component: () =>
      import(
        /* webpackChunkName: "reviews-create" */
        '@/views/reviews/create/Create'
      ),
  },
  {
    path: '/reviews/thank-you',
    name: 'reviews.thank-you',
    meta: {
      layout: 'Default',
      middleware: auth,
    },
    component: () =>
      import(
        /* webpackChunkName: "reviews-create" */
        '@/views/reviews/thankYou/ThankYou'
      ),
  },
];
