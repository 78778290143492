import defaultDetectors from './detectors';

export default async function detectPreferredLanguage(locales, detectorList) {
  let preferredLanguage;
  const detectors = detectorList || defaultDetectors;

  try {
    preferredLanguage = await detectors.reduce(
      (pipes, detector) =>
        pipes.catch(async (error) => {
          const language = await detector(locales);
          if (language) {
            return Promise.resolve(language);
          }
          return Promise.reject(error);
        }),
      Promise.reject()
    );
  } catch (error) {
    // if (error) {
    // console.error(error.message);
    // }
  }

  return preferredLanguage
    ? Promise.resolve(preferredLanguage)
    : Promise.reject(preferredLanguage);
}
