const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
const USER_ID = 'userId';

export default {
  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  },

  setAccessToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  },

  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
  },

  hasAccessToken() {
    return !!this.getAccessToken();
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  },

  setRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN);
  },

  hasRefreshToken() {
    return !!this.getRefreshToken();
  },

  getUserId() {
    return localStorage.getItem(USER_ID);
  },

  setUserId(userId) {
    localStorage.setItem(USER_ID, userId);
  },

  removeUserId() {
    localStorage.removeItem(USER_ID);
  },

  hasUserId() {
    return !!this.getUserId();
  },
};
