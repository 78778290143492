import api from '@/http/client';

export default {
  async register(payload) {
    return api.$post('/register', payload);
  },

  async login(payload) {
    return api.$post('/login', payload);
  },

  async refreshToken(payload) {
    return api.$post('/auth-token-refresh', payload);
  },

  async requestPassword(email) {
    return api.$post('/forgot-password', { email });
  },

  // async resetPassword(token) {
  //   /* eslint-disable no-console */
  //   console.log(1);
  //   // eslint-disable-next-line no-debugger
  //   debugger;
  //   return api.$post('/reset-password', { token });
  // },

  async confirmRegistration(token) {
    return api.$post('/register-confirm', { token });
  },
};
