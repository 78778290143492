export default {
  namespaced: true,

  state: {
    nationalities: {},
    nationalitiesErrorMessage: '',
  },

  getters: {
    nationalities(state) {
      return Object.values(state.nationalities).sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
      });
    },
  },

  mutations: {
    setNationalities(state, nationalities) {
      state.nationalities = nationalities;
    },
    setNationalitiesError(state, message) {
      state.nationalitiesErrorMessage = message;
    },
  },
};
