import loadUser from '@/middlewares/loadUser';
import loadLayout from '@/middlewares/loadLayout';
import loadTranslations from '@/middlewares/loadTranslations';

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);

    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

function middlewaresPerRoute(middlewares) {
  if (middlewares) {
    return Array.isArray(middlewares) ? middlewares : [middlewares];
  }

  return [];
}

const beforeEach = (to, from, next) => {
  if (from.name !== to.name) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  const middlewaresForEachRoute = [loadUser, loadLayout, loadTranslations];

  const middlewares = [
    ...middlewaresForEachRoute,
    ...middlewaresPerRoute(to.meta.middleware),
  ];

  const context = { from, next, to };
  const nextMiddleware = nextFactory(context, middlewares, 1);
  return middlewares[0]({ ...context, next: nextMiddleware });
};

export default beforeEach;
