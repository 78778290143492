import auth from '@/api/auth';
import storage from '@/services/tokenStorage';
import api from '@/http/client';
import store from '@/store';

export default {
  async register(payload) {
    await auth.register(payload);

    // store.commit('user/setUser', response.userId);
    // store.commit('auth/loginSuccess', response);

    // return this.onLoginSuccess(response);
  },

  async login(payload) {
    store.commit('auth/loginRequest');

    try {
      const response = await auth.login(payload);

      store.commit('auth/loginSuccess', response);

      return this.onLoginSuccess(response);
    } catch (error) {
      store.commit('auth/loginError', {
        errorCode: error.response.status,
        errorMessage: error.response.data.message,
      });
      return false;
    }
  },

  logout() {
    storage.removeUserId();
    storage.removeAccessToken();
    storage.removeRefreshToken();

    api.removeAuthorizationHeader();

    store.commit('user/setUser', null, { root: true });
    store.commit('auth/logoutSuccess');
  },

  refreshToken() {
    const state = store.state.auth;

    if (state.refreshTokenPromise) {
      return state.refreshTokenPromise;
    }

    const refreshTokenPromise = auth.refreshToken({
      userId: storage.getUserId(),
      refreshToken: storage.getRefreshToken(),
    });

    store.commit('auth/refreshTokenPromise', refreshTokenPromise);

    // Wait for promise resolving. On success set the token and clear promise
    // Clear the promise on error as well.
    return refreshTokenPromise
      .then((response) => this.onRefreshTokenResolved(response))
      .catch((error) => this.onRefreshTokenRejected(error));
  },

  onRefreshTokenResolved(response) {
    store.commit('auth/refreshTokenPromise', null);
    store.commit('auth/loginSuccess', this.onLoginSuccess(response));
  },

  onRefreshTokenRejected() {
    store.commit('auth/refreshTokenPromise', null);
  },

  onLoginSuccess({ userId, accessToken, refreshToken }) {
    storage.setUserId(userId);
    storage.setAccessToken(accessToken);
    storage.setRefreshToken(refreshToken);

    api.setAuthorizationHeader(accessToken);

    return { userId, accessToken, refreshToken };
  },
};
