import guest from '@/middlewares/guest';

export default [
  {
    path: '/signup',
    name: 'signup',
    meta: {
      layout: 'Default',
      middleware: guest,
    },
    component: () =>
      import(/* webpackChunkName: "signup" */ '@/views/auth/signup/SignUp'),
  },

  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'Default',
      middleware: guest,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/login/Login'),
  },

  {
    path: '/password/request',
    name: 'password-request',
    meta: {
      layout: 'Default',
      middleware: guest,
    },
    component: () =>
      import(
        /* webpackChunkName: "password-request" */
        '@/views/auth/password/Request'
      ),
  },

  // {
  //   path: '/reset-password',
  //   name: 'password-reset',
  //   meta: {
  //     layout: 'Default',
  //     middleware: guest,
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "password-reset" */
  //       '@/views/auth/password/Reset'
  //     ),
  // },

  {
    path: '/confirm-registration',
    name: 'confirm-registration',
    meta: {
      layout: 'Default',
      middleware: guest,
    },
    component: () =>
      import(
        /* webpackChunkName: "confirm-registration" */
        '@/views/auth/confirmRegistration/ConfirmRegistration'
      ),
  },
];
