import axios from 'axios';
import URLS from '../constants/urls';

const AUTHORIZATION_HEADER = 'Authorization';

const api = {
  dataHelpers: ['get', 'post', 'put', 'patch', 'delete'],

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  setHeader(key, value) {
    axios.defaults.headers.common[key] = value;
  },

  removeHeader(key) {
    delete axios.defaults.headers[key];
  },

  setAuthorizationHeader(token) {
    this.setHeader(AUTHORIZATION_HEADER, token);
  },

  removeAuthorizationHeader() {
    this.removeHeader(AUTHORIZATION_HEADER);
  },

  get(resource, params = {}) {
    return axios.get(resource, { params });
  },

  post(resource, data, params = {}) {
    return axios.post(resource, data, params);
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  customRequest(data) {
    return axios(data);
  },

  /**
   * Define helper handler to auto extract the last 'data' attribute from object value.
   */
  defineDataHelpers() {
    this.dataHelpers.forEach((method) => {
      this[`$${method}`] = (...args) =>
        this[method](...args).then(this.extractLastDataAttribute.bind(this));
    });
  },

  /**
   * Extract the last 'data' attribute from object value.
   *
   * @param {mix} value
   * @param {integer} maxDepth
   */
  extractLastDataAttribute(value, maxDepth = 2) {
    if (value instanceof Object && value.data && maxDepth > 0) {
      return this.extractLastDataAttribute(value.data, maxDepth);
    }

    return value;
  },

  mountInterceptor(interceptor) {
    interceptor.mount();
  },
};

api.init(URLS.API_URL);
api.defineDataHelpers();

export default api;
