export default [
  {
    path: '/books',
    name: 'books',
    meta: {
      layout: 'Default',
      hasContentSideBar: true,
    },
    component: () => import('@/views/books/index/Books'),
  },

  {
    path: '/books/no-result',
    name: 'books.no-result',
    meta: {
      layout: 'Default',
    },
    component: () => import('@/views/books/noResult/NoResult'),
  },

  {
    path: '/books/recent',
    name: 'books.recent',
    meta: {
      layout: 'Default',
    },
    component: () => import('@/views/books/recent/RecentBooks'),
  },

  {
    path: '/books/create',
    name: 'books.create',
    meta: {
      layout: 'Default',
    },
    component: () => import('@/views/books/createBook/CreateBook'),
  },

  {
    path: '/books/:isbn',
    name: 'books.show',
    meta: {
      layout: 'Default',
    },
    component: () => import('@/views/books/show/Book'),
  },
];
