import api from '@/http/client';

export default {
  get() {
    return api.$get(`/users/profile`);
  },

  update(data) {
    return api.$put(`/users/profile`, data);
  },
};
