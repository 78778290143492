import { i18n } from '@/i18n';

export default function localePath(route, params = {}, query = {}) {
  const lang = i18n.locale === i18n.fallbackLocale ? undefined : i18n.locale;

  if (typeof route === 'string') {
    return { name: route, params: { lang, ...params }, query };
  }

  if (route instanceof Object) {
    return { ...route, params: { lang, ...route.params }, query };
  }

  throw new Error('Undefined route');
}
