import Locale from '@/i18n/Locale';
import locales from '@/i18n/locales.json';

class Locales {
  constructor() {
    this.locales = locales.map((locale) => new Locale(locale));
  }

  all() {
    return this.locales;
  }

  get supported() {
    return this.locales.filter((locale) => locale.supported);
  }
}

export default Locales;
