import auth from '@/middlewares/auth';

export default [
  {
    path: '/profile',
    name: 'profile',
    meta: {
      layout: 'Default',
      middleware: auth,
      hasContentSideBar: true,
    },
    component: () => import('@/views/profile/Profile'),
  },
];
