import storage from '@/services/tokenStorage';
import store from '@/store';

// Fetch user profile if authenticated
export default async function ({ next }) {
  const loggedIn = storage.hasAccessToken();

  if (loggedIn && !store.state.user.user) {
    await store.dispatch('user/load');
  }

  next();
}
