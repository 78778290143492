import { loadTranslationsAsync } from '@/i18n';
import detectPreferredLanguage from '@/i18n/detectLanguage';
import store from '@/store';

export default function loadTranslations({ to, next }) {
  if (to.params.lang) {
    loadTranslationsAsync(to.params.lang).then(() => next());
  } else {
    detectPreferredLanguage(store.state.locales)
      .then((lang) => loadTranslationsAsync(lang).then(() => next()))
      .catch(() => next());
  }
}
