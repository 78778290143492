<template>
  <div id="app">
    <component :is="layout" :key="$route.meta.theme">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      layout: 'layout',
    }),
  },
  mounted() {
    window.history.scrollRestoration = 'manual';
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';
@import '@/assets/styles/_tooltips.scss';
</style>

dbmsnakds,n bfdsj.kfbvsdfjdsk,fds,fdjsk,fdshlkdjhsadjaskaskldjhsahd;skdsaudgkdhl
