import Vue from 'vue';
import Vuex from 'vuex';
import Locales from '@/i18n/LocaleCollection';
import user from './user';
import auth from './auth';
import books from './books';
import nationalities from './nationalities';
import constants from '../api/constants';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    layout: 'div',
    locales: new Locales(),
    fontsLoaded: false,
    constants: null,
  },

  getters: {
    locales(state) {
      return state.locales;
    },
  },

  mutations: {
    setLayout(state, layout) {
      state.layout = layout;
    },

    setFontsLoaded(state, loaded) {
      state.fontsLoaded = loaded;
    },

    setConstants(state, c) {
      state.constants = c;
    },
  },

  actions: {
    async fetchConstants({ state, commit }) {
      if (!state.constants) {
        const result = await constants.get();
        commit('setConstants', result);
      }
    },
  },

  modules: {
    auth,
    user,
    books,
    nationalities,
  },
});
