import helpers from '../helpers/helpers';

export default {
  namespaced: true,

  state: {
    books: [],
    book: null,
    reviews: [],
    error: null,
    lastScrolledBook: '',
  },

  getters: {
    books(state) {
      const delimiter = Math.round(state.books.length / 2);
      return helpers.splitTo(state.books, delimiter);
    },
    book(state) {
      return state.book;
    },
    lastScrolledBook(state) {
      return state.lastScrolledBook;
    },
  },

  mutations: {
    setBooks(state, books) {
      state.books = books;
    },
    setBook(state, book) {
      state.book = book;
    },
    setBokReviews(state, reviews) {
      state.book.Reviews = reviews;
    },
    setError(state, error) {
      state.error = error;
    },
    setLastScrolledBook(state, id) {
      state.lastScrolledBook = id;
    },
  },
};
