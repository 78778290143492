import storage from '@/services/tokenStorage';

export default {
  namespaced: true,

  state: {
    accessToken: storage.getAccessToken(),
    refreshToken: storage.getRefreshToken(),
    refreshTokenPromise: null,
    authenticating: false,
    authenticationErrorCode: 0,
    authenticationError: '',
    confirmationMessageSuccess: '',
  },

  getters: {
    loggedIn(state) {
      return !!state.accessToken;
    },

    authenticationErrorCode(state) {
      return state.authenticationErrorCode;
    },

    authenticationError(state) {
      return state.authenticationError;
    },

    authenticating(state) {
      return state.authenticating;
    },
  },

  mutations: {
    loginRequest(state) {
      state.authenticating = true;
      state.authenticationError = '';
      state.authenticationErrorCode = 0;
    },

    loginSuccess(state, { accessToken, refreshToken }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.authenticating = false;
    },

    loginError(state, { errorCode, errorMessage }) {
      state.authenticating = false;
      state.authenticationErrorCode = errorCode;
      state.authenticationError = errorMessage;
    },

    logoutSuccess(state) {
      state.accessToken = null;
      state.refreshToken = null;
    },

    confirmRegistrationSuccess(state, message) {
      state.confirmationMessageSuccess = message;
    },

    confirmRegistrationError(state, message) {
      state.confirmationMessage = message;
    },

    refreshTokenPromise(state, promise) {
      state.refreshTokenPromise = promise;
    },
  },
};
