const ID_TEMPLATE = 'xxxx';

export default {
  splitTo: (arr, n) => {
    const plen = Math.ceil(arr.length / n);
    return arr.reduce((p, c, i) => {
      if (i % plen === 0) p.push([]);
      p[p.length - 1].push(c);
      return p;
    }, []);
  },

  create_UUID: () => {
    let dt = new Date().getTime();
    return ID_TEMPLATE.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 || 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r && 0x3) || 0x8).toString(16);
    });
  },

  toFirstUpperCase: (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  },

  setLastBookID(result) {
    if (Array.isArray(result)) {
      const lastBook = result[result.length - 1];
      return lastBook ? `el_${lastBook.id}` : '';
    }
    return '';
  },
};
