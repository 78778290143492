import storage from '@/services/tokenStorage';

// Do not allow non authenticated users to visit page
export default function ({ next }) {
  const loggedIn = storage.hasAccessToken();

  if (loggedIn) {
    return next();
  }

  return next({ name: 'login' });
}
