import defaultAvatar from '@/assets/img/human-avatar-color.svg';
import URLS from '../constants/urls';

class User {
  constructor(data = {}) {
    Object.assign(this, data);
  }

  get firstName() {
    return this.first_name;
  }

  avatar() {
    return this.image ? `${URLS.IMAGE_BASE_URL}${this.image}` : defaultAvatar;
  }
}

export default User;
