import profile from '@/api/profile';
import User from '@/models/User';

export default {
  namespaced: true,

  state: {
    user: null,
  },

  getters: {
    user(state) {
      return state.user;
    },

    userModel(state) {
      return new User(state.user);
    },
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },

  actions: {
    async load({ commit }) {
      commit('setUser', await profile.get());
    },
  },
};
