const PREFERRED_LANGUAGE = 'lang';

export default {
  getLocale() {
    return localStorage.getItem(PREFERRED_LANGUAGE);
  },

  saveLocale(locale) {
    localStorage.setItem(PREFERRED_LANGUAGE, locale);
  },

  removeLocale() {
    localStorage.removeItem(PREFERRED_LANGUAGE);
  },

  hasLocale() {
    return !!this.getLocale();
  },
};
