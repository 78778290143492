import axios from 'axios';
import authService from '@/services/authService';
import tokenStorage from '@/services/tokenStorage';
import router from '@/router';

class AuthErrorInterceptor {
  constructor() {
    this.auth = authService;
  }

  mount() {
    return axios.interceptors.response.use(
      (response) => {
        return response;
      },

      async (error) => {
        if (error.request.status !== 401 || !tokenStorage.getRefreshToken()) {
          return Promise.reject(error);
        }

        if (error.config.url.includes('/auth-token-refresh')) {
          // Refresh token has failed. Logout the user
          await this.auth.logout();

          router.push({ name: 'login' });
          return Promise.reject(error);
        }

        // Refresh the access token
        try {
          await this.auth.refreshToken();

          // Retry the original request
          return axios({
            method: error.config.method,
            url: error.config.url,
            data: error.config.data,
          });
        } catch (e) {
          // Refresh has failed - reject the original request
          return Promise.reject(e);
        }
      }
    );
  }
}

export default AuthErrorInterceptor;
