class Locale {
  constructor({ code, label, supported, alias, description }) {
    this.code = code;
    this.label = label;
    this.supported = supported;
    this.alias = alias;
    this.description = description;

    this.validate();
  }

  validate() {
    if (!this.supported && !this.alias) {
      throw new Error(
        `Locale ${this.code} is not supported and has no related locale`
      );
    }

    return true;
  }
}

export default Locale;
